import Sortable from "stimulus-sortable"

export default class extends Sortable {
  connect() {
    super.connect()
    console.log("Do what you want here.")

    // The sortable.js instance.
    console.log(this.sortable)

    // Your options
    console.log(this.options)

    // Your default options
    //this.defaultOptions
  }

  // You can override the `end` method here.
  // end () {
  //   super.end()
  // }

  

  // You can set default options in this getter for all sortable elements.
  get defaultOptions () {
    return {
      animation: 150,
      onUpdate(ev) {
        console.log(ev)
        console.log(ev.target)
        let elements = ev.target.querySelectorAll('.position')
        console.log(elements)
        elements.forEach((element, index) => {
          element.value = index + 1
        })
        console.log(elements)
        
      }
    }
  }
}
