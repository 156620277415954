import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "deliveryAddressFields" ]

  showDeliveryAddressFields() {
    this.deliveryAddressFieldsTarget.classList.toggle('hidden');
    console.log("foo")
  }
    calculatePrice() {
    console.log('calculate price')
  }

}