import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "total", "taxes", "withoutTax", "totalWithShipping" ]


  connect() {
  }

  setTotal(total) {
    this.totalTarget.textContent = total
  }

  setItemTotal(id, total) {
    document.querySelector(`[data-item-id='item_quantity_${id}']`).innerHTML = total;
  }

  setQuantity(id, quantity) {
    document.querySelector(`#input-quantity-${id}`).value = quantity;
  }

  setTaxes(taxes) {
    this.taxesTarget.textContent = taxes
  }

  setPriceWithoutTax(price) {
    this.withoutTaxTarget.textContent = price

  }

  setTotalWithShipping(total) {
    this.totalWithShippingTarget.textContent = total
  }

  updateQuantity(e) {
    let that = this;
    Rails.ajax({
      type: "POST",
      datatype: 'json',
      url: "/cart/update_quantity",
      data: new URLSearchParams({ product_id: e.target.dataset.productId, product_quantity_id: e.target.dataset.productQuantityId, quantity: e.target.value }).toString(),
      success: function(response){
        that.setItemTotal(response.product_quantity_id, response.item_total);
        that.setTotal(response.total);
        that.setQuantity(response.product_quantity_id, response.quantity);
        that.setTaxes(response.taxes);
        that.setPriceWithoutTax(response.price_without_tax);
        that.setTotalWithShipping(response.price_with_shipping);
        //that.setTotal(response.foo);
        //this.setItemTotal(response.foo)
      },
      error: function(response){
        console.log("error");
      }
    })
  }


  sayHello() {
      console.log("hello");
      let that = this;
      Rails.ajax({
        type: "POST", 
        url: "/cart/update_quantity",
        data: { product_id: 1, quantity: 1 },
        success: function(response){
          console.log("success");
          console.log(response);
          that.setTotal(response.foo);
          this.setItemTotal(response.foo)
        },
        error: function(response){
          console.log("error");
        }
      })
  }

  
}