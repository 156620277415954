import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "quantity", "variants", "price", "form" ];
  static values = { url: String };
  
  connect() {
    console.log('connected');
    this.getContainerSizes();
    // await this.getQuantities();
  }


  setDefaultContainerSize() {
    console.log('set default container size');
    console.dir(this.variantsTarget.options);
    
    if ([...this.variantsTarget.options].map(o => o.value).includes("2")) {
      this.variantsTarget.value = "2";
    } else {
      console.log("is null")
    }
    this.getQuantities();
  }

  getPrice(e) {
    console.log('get price');
    this.calculatePrice(this.element.dataset.productid)
  }

  calculatePrice(product_id) {
    console.log('calculate price')
    console.dir(product_id)
    //this.priceTarget.classList.add("hidden")
    this.priceTarget.innerHTML = "<i class='fa fa-spinner fa-pulse'/>"
    Rails.ajax({
      type: "GET",
      url: `/products/${product_id}/calculate_price?quantity=${this.quantityTarget.value}&variant_id=${this.variantsTarget.value}`,
      //data: new URLSearchParams({ product_id: e.target.dataset.productId, quantity: e.target.value }).toString(),
      success: (response) => {
        //that.setItemTotal(response.product_id, response.item_total);
        //that.setTotal(response.total);
        //that.setTotal(response.remove);
        //this.setItemTotal(response.foo)
        console.log("success");
        console.log(response);
        this.priceTarget.innerHTML = response.price
        //this.priceTarget.classList.remove("hidden")
      },
      error: function(response){
        console.log("error");
      }
    })
  }
  getQuantities() {
    this.quantityTarget.innerHTML = "<option value='1'>...</option>"
    Rails.ajax({
      type: "GET",
      url: `/products/${this.element.dataset.productid}/${this.variantsTarget.value}/quantities`,
      success: (response) => {
        console.log(response);
        this.quantityTarget.innerHTML = ""
        for (let i = 1; i <= response.quantity; i++) {
          const option = document.createElement("option");
          option.dir="rtl";
          option.value = i;
          option.innerHTML = i;
          this.quantityTarget.appendChild(option)
        }
       //this.variantsTarget.innerHTML = "";
      //  response.variants.forEach((variant) => {
      //    const option = document.createElement("option");
      //    option.value = variant.id;
      //    option.innerHTML = variant.size;
      //    this.variantsTarget.appendChild(option);
      //  })
     }
    })
  }

  getContainerSizes() {
    Rails.ajax({
      type: "GET",
      url: `/products/${this.element.dataset.productid}/variants`,
     success: (response) => {
       console.log(response);
       this.variantsTarget.innerHTML = "";
       response.variants.forEach((variant) => {
         const option = document.createElement("option");
         option.value = variant.id;
         option.innerHTML = variant.title;
         this.variantsTarget.appendChild(option);
       })
       this.setDefaultContainerSize();
       //this.getQuantities()
       this.calculatePrice(this.element.dataset.productid)
     }
    })
  }
}