// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Application } from "@hotwired/stimulus"
// import Swiper from "swiper";
import Carousel from "stimulus-carousel"

import { Modal } from 'tailwindcss-stimulus-components'
import Lightbox from "stimulus-lightbox"
import "lightgallery.js/dist/css/lightgallery.min.css"

const application = Application.start()


Rails.start()
Turbolinks.start()
ActiveStorage.start()
import "controllers"
import "stylesheets/application"
import "@fontsource/pathway-gothic-one"
import "@fontsource/italianno"
import "@fontsource/raleway"
import "@fontsource/montserrat"
import "@fortawesome/fontawesome-free/css/all"

application.register('carousel', Carousel)
application.register('modal', Modal)
application.register("lightbox", Lightbox)
// const swiper = new Swiper('.swiper', {loop: true});

// window.addEventListener('scroll', function() {
//   if (window.scrollY >=400) { // adjust this value based on site structure and header image height
//     document.getElementById('site-menu').classList.add('nav-sticky');
//     document.getElementById('top').classList.add('pt-scroll');
//   } else {
//     document.getElementById('site-menu').classList.remove('nav-sticky');
//     document.getElementById('top').classList.remove('pt-scroll');
//   }
// });

function navToggle() {
        var btn = document.getElementById('menuBtn');
        var nav = document.getElementById('menu');

        btn.classList.toggle('open');
        nav.classList.toggle('flex');
        nav.classList.toggle('hidden');
    }

window.navToggle = navToggle;


document.addEventListener("turbolinks:load", function() {
    if (document.getElementById("paypal-button-container") ) {
        var rails_env = document.body.dataset.env
        paypal.Buttons({
            env: rails_env === 'production' ? 'live' : 'sandbox', // Valid values are sandbox and live.
            //env: 'sandbox',
            createOrder: async () => {
                const response = await fetch('/create_order', {method: 'POST'}); 
                const responseData = await response.json(); 
                return responseData.token;
            },   
            onApprove: async (data) => {
                //const response = await fetch('/capture_order?order_id=' + data.orderID, {method: 'POST'}); 
                //const responseData = await response.json(); 
                //alert("success");
                console.log(data.orderID);
                document.getElementById("checkout_paypal_order_id").value = data.orderID.toString();
                document.getElementById("checkout-accept-terms").classList.remove('hidden');
                document.getElementById("submit-checkout-button").classList.remove('hidden');
                //return responseData;
            },
            }).render('#paypal-button-container');
        }
});

require("trix")
require("@rails/actiontext")